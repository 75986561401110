import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";

import { Layout, PostCard } from "../components";
import { MetaData } from "../../../components/meta";

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;

const PostFeed = styled.div`
    position: relative;
    display: grid;
    gap: 4.8vmin 4vmin;
    grid-template-columns: repeat(6, 1fr);
    padding: max(4.8vmin, 36px) 0 0;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
    @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const Index = ({ data, location }) => {
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData location={location} />
            <Layout isHome>
                <main className="site-main">
                    <Container>
                        <PostFeed>
                            {posts.map(({ node }) => (
                                <PostCard key={node.id} post={node} />
                            ))}
                        </PostFeed>
                    </Container>
                </main>
            </Layout>
        </>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        ghostSettings: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query GhostPostQuery($skip: Int!) {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                    primary_tag {
                        name
                    }
                }
            }
        }
        allGhostTag(
            filter: {
                count: { posts: { gt: 1 } }
                visibility: { eq: "public" }
            }
            limit: 6
            sort: { order: DESC, fields: count___posts }
        ) {
            edges {
                node {
                    name
                    slug
                    postCount
                }
            }
        }
        ghostSettings {
            logo
            description
        }
    }
`;
